/* eslint-disable @typescript-eslint/indent */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React, { FC } from 'react'
import './App.css'
import { Switch, Route, useLocation } from 'react-router-dom'
import * as restAPI from '../../utils/api/reat-api'
import * as saleAPI from '../../utils/api/sale-api'
import * as menuAPI from '../../utils/api/menu-api'
import Main from '../../pages/main/main'
import Cart from '../../pages/cart/cart'
import NotFound from '../../pages/not-found/not-found'
import {
  ECountry,
  TDish,
  TRest,
  TSale,
  TSubCategories
} from '../../utils/typesFromBackend'
import SaleModal from '../sale-modal/sale-modal'
import DishModal from '../dish-modal/dish-modal'
import AuthorizationUser from '../../pages/authorization-user/authorization-user'
import Orders from '../../pages/orders/orders'
import OrderMade from '../../pages/order-made/order-made'
import Person from '../../pages/person/person'
import ProtectedRoute from '../protected-route/protected-route'
import PersonUpdate from '../../pages/person-update/person-update'
import PD from '../../pages/pd/pd'
import Notification from '../notification/notification'
import { useSelector } from 'react-redux'
import { RootState } from '../../services/store'
import { CustomSpinner } from '../spinners/customSpinner/customSpinner'
import AdultIsNotConfirmed from '../adult/adultNotСonfirmed'
import { useTranslation } from 'react-i18next'
import i18n from '../i18tn/i18tn'
import { useDispatch } from '../../services/hooks/redux-hooks'
import { setNotification } from '../../services/actions/notification'
import { BASE_URL_CDN, testIdRest } from '../../utils/const'
import {
  CurrentLanguageProvider,
  useCurrentLanguage
} from '../../contexts/CurrentLanguageContext'
import { SetLanguageProvider } from '../../contexts/SetLanguageContext'
import { TextTranslationProvider } from '../../contexts/TextTranslation'
import {
  correctPositionWhenCloseModal,
  correctPositionWhenOnModal
} from '../../utils/utils'
import AboutUs from '../../pages/about-us/about-us'
import PaymentSuccess from '../../pages/payment-success/payment-success'
import PaymentFailure from '../../pages/payment-failure/payment-failure'
import { YandexCityProvider } from '../../contexts/YandexCityProvider'
import TermsOfPayment from '../../pages/terms-of-payment/terms-of-payment'
import ConditonForReturning from '../../pages/condition-for-returning-goods/condition-for-returning-goods'
import Offer from '../../pages/offer/offer'
import style from './app.module.css'
import { Helmet } from 'react-helmet'
import RestsList from '../../pages/rests-list/rests-list'
import { showCategoriesCardView } from '../../services/actions'
import upArrow from '../../assets/images/up_arrow.png'

interface ILocationDetail {
  building: string
  city: string
  coordinates: {
    latitude: number
    longitude: number
  }
  country: string
  flat: string | null
  house: string
  porch: string | null
  sfloor: string | null
  street: string
  _id: string
}

interface IRest {
  languages: string[]
  location_detail: ILocationDetail | null
  titleRest: string
  logoPath: string
  _id: string
}

interface IRests {
  rests: IRest[]
}

export interface INet {
  data: IRests
}

const App: FC = () => {
  const [isOpenDishModal, setIsOpenDishModal] = React.useState(false)
  const [isOpenSaleModal, setIsOpenSaleModal] = React.useState(false)
  const [isNoImageDishInModal, setIsNoImageDishInModal] = React.useState(false)
  const [activeModifiersInModal, setActiveModifiersInModal] =
    React.useState(false)
  const [dishInModal, setDishInModal] = React.useState<TDish>({} as TDish)
  const [saleInModal, setSaleInModal] = React.useState<TSale>({} as TSale)
  const [isScrollButtonVisible, setIsScrollButtonVisible] = React.useState(true)
  // состояние "Загрузилось"
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [isImageLoaded, setIsImageLoaded] = React.useState(true)
  // полный объект ресторана
  const [rest, setRest] = React.useState<TRest>({} as TRest)
  // действующие акции
  const [sales, setSales] = React.useState<TSale[]>([])
  const [menu, setMenu] = React.useState<TSubCategories[]>([])

  // eslint-disable-next-line
  const [isLoadingSpiner, setIsLoadingSpiner] = React.useState(false)
  const [showCart, setShowCart] = React.useState<boolean>(false)
  const [isAdult, setIsAdult] = React.useState<boolean>(false)
  const [isDishLoaded, setIsDishLoaded] = React.useState(false)
  // состояние выбранного ресторана
  const [selected, setSelected] = React.useState('')
  const location = useLocation()
  // состояние "Это сеть?"
  const [isNet, setIsNet] = React.useState(false)
  // список ресторанов сети
  const [net, setNet] = React.useState<INet>({} as INet)
  const { message, type } = useSelector(
    (state: RootState) => state.notification
  )
  const cartState = useSelector((state: RootState) => state.cart)
  const [language, setLanguage] = React.useState<ECountry>(
    (localStorage.getItem('preferredLanguage') as ECountry) ?? 'RU'
  )
  const [isArrowUpButtonVisible, setIsArrowUpButtonVisible] =
    React.useState(false)
  const toggleVisibility = (): void => {
    if (window.scrollY > 300) {
      setIsArrowUpButtonVisible(true)
    } else {
      setIsArrowUpButtonVisible(false)
    }
  }

  const styleCustomColor = {
    backgroundColor: rest?.colorsSchema_id?.mainTextColor
  }

  const scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  // слушатель события скролла для отображения кнопки вверх
  React.useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)
    return () => {
      window.removeEventListener('scroll', toggleVisibility)
    }
  }, [])

  // установка фавикона для конкретного ресторана на вкладке
  React.useEffect(() => {
    if (rest.logoPath) {
      const favicon = `${BASE_URL_CDN + '/' + `${rest.logoPath}`}`
      const link = document.createElement('link')
      link.rel = 'icon'
      link.type = 'image/x-icon'
      link.href = favicon
      const head = document.querySelector('head')
      if (head) {
        head.appendChild(link)
      }
    }
  }, [rest.logoPath])

  // установка title для конкретного ресторана на вкладке
  React.useEffect(() => {
    if (rest?.titleRest) {
      document.title = rest.titleRest
    }
  }, [rest.titleRest])

  // если тариф "Basic" - не отображать функционал корзины :)
  React.useEffect(() => {
    if (rest.tariff === 'Basic') {
      setShowCart(false)
    } else {
      setShowCart(true)
    }
  }, [rest.tariff])

  const { t } = useTranslation()

  const dispatch = useDispatch()

  // функция смены языка
  const changeLanguage = React.useCallback((lng: ECountry) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    i18n.changeLanguage(lng)
    localStorage.setItem('preferredLanguage', lng)
    setLanguage(lng)
  }, [])

  // установка языка по умолчанию?
  React.useEffect(() => {
    const lng = localStorage.getItem('preferredLanguage')
    if (lng) {
      document.documentElement.setAttribute('lang', lng.toLocaleLowerCase())
    } else if (rest.country) {
      document.documentElement.setAttribute(
        'lang',
        rest.country.toLocaleLowerCase()
      )
    }
  }, [rest.country])

  // установка языка?
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    i18n.changeLanguage(language)
  }, [language])

  // сохранение состояния корзины в localStorage
  React.useEffect(() => {
    const cartStateString = JSON.stringify(cartState)
    localStorage.setItem('cartState', cartStateString)
  }, [cartState])

  const closeModal = (): void => {
    correctPositionWhenCloseModal()
    setIsOpenDishModal(false)
    setIsOpenSaleModal(false)
    setIsScrollButtonVisible(true) // Показать кнопку прокрутки
  }

  const openSaleModal = (sale: TSale): void => {
    correctPositionWhenOnModal()
    setSaleInModal(sale)
    setIsOpenSaleModal(true)
    setIsScrollButtonVisible(false) // Скрыть кнопку прокрутки
  }

  const openDishModal = (
    isNoImage: boolean,
    activeModifiers: boolean,
    dish: TDish
  ): void => {
    correctPositionWhenOnModal()
    setIsNoImageDishInModal(isNoImage)
    setActiveModifiersInModal(activeModifiers)
    setDishInModal(dish)
    setIsOpenDishModal(true)
    setIsScrollButtonVisible(false) // Скрыть кнопку прокрутки
  }

  // USE EFFECT 1
  // обработка выбора ресторана из списка selected
  React.useEffect(() => {
    if (selected !== '') {
      setIsNet(false)
      const fetchData = async (): Promise<void> => {
        setIsLoadingSpiner(true)
        try {
          const dataRest = await restAPI.getRest(selected)
          setRest(dataRest)
          // обновить store в части показа категорий плиткой
          if (dataRest.colorsSchema_id.isCardViewOfCategories) {
            dispatch(showCategoriesCardView(true))
          }

          if (dataRest.yandexMetrika && dataRest.yandexMetrika !== '') {
            ;(function (
              m: any,
              e: any,
              t: string,
              r: string,
              i: string,
              k: any,
              a: any
            ) {
              m[i] =
                m[i] ||
                function () {
                  ;(m[i].a = m[i].a || []).push(arguments)
                }
              m[i].l = Date.now()
              // eslint-disable-next-line
              ;(k = e.createElement(t)),
                (a = e.getElementsByTagName(t)[0]),
                (k.async = 1),
                (k.src = r),
                a.parentNode.insertBefore(k, a)
            })(
              window,
              document,
              'script',
              'https://mc.yandex.ru/metrika/tag.js',
              'ym',
              undefined,
              undefined
            )
            // @ts-expect-error
            window.ym(dataRest.yandexMetrika, 'init', {
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
              webvisor: true
            })
          }
          setIsAdult(dataRest.isAdult)
          if (localStorage.getItem('preferredLanguage') == null) {
            setLanguage(dataRest.country)
          }
          // получение данных об акциях и меню
          const [dataSales, dataMenu] = await Promise.all([
            saleAPI.getAllSales(dataRest._id),
            menuAPI.getMenu(dataRest._id)
          ])
          setSales(dataSales)
          setMenu(dataMenu)
          setIsLoaded(true) // вот тут начинает грузиться Main
          setIsLoadingSpiner(false)
        } catch (error) {
          setIsLoadingSpiner(false)
          showAlert(error as string, 'danger')
        }
      }
      void fetchData()
    }
  }, [selected])

  // USE EFFECT 2
  // первичная проверка является ли ресторан сетью
  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const restIdFromUrl = searchParams.get('rest_id')
    if (restIdFromUrl) {
      // если есть rest_id в url - то сразу ставим его в selected
      setSelected(restIdFromUrl)
      return
    }
    restAPI
      // проверка CheckForChain
      .getNetRest()
      .then((res: INet) => {
        if (res.data.rests.length > 1) {
          // это сеть и в URL нет rest_id
          setIsNet(true) // ставим стейт isNet в true
          setNet(res) // результат запроса - массив объектов ресторонов rests (там не все данные о ресторане, а сокращенные) - записываем в стейт net
        } else {
          // это не сеть
          const fetchData = async (): Promise<void> => {
            setIsLoadingSpiner(true)
            try {
              const dataRest = await restAPI.getRest(
                // дергаем ручку getRest
                // если не localHost, то ничего не передаем в getRest и он отрабатывает просто по BASE_URLURL с наименованием ресторана
                // return await fetch(`${BASE_URL}/rest`
                window.location.href.includes('http://localhost:3000')
                  ? testIdRest
                  : undefined
              )
              setRest(dataRest) // результат запроса - полный объект ресторана - записываем в стейт rest
              // обновить store в части показа категорий плиткой
              if (dataRest.colorsSchema_id.isCardViewOfCategories) {
                dispatch(showCategoriesCardView(true))
              }
              if (dataRest.yandexMetrika && dataRest.yandexMetrika !== '') {
                ;(function (
                  m: any,
                  e: any,
                  t: string,
                  r: string,
                  i: string,
                  k: any,
                  a: any
                ) {
                  m[i] =
                    m[i] ||
                    function () {
                      ;(m[i].a = m[i].a || []).push(arguments)
                    }
                  m[i].l = Date.now()
                  // eslint-disable-next-line
                  ;(k = e.createElement(t)),
                    (a = e.getElementsByTagName(t)[0]),
                    (k.async = 1),
                    (k.src = r),
                    a.parentNode.insertBefore(k, a)
                })(
                  window,
                  document,
                  'script',
                  'https://mc.yandex.ru/metrika/tag.js',
                  'ym',
                  undefined,
                  undefined
                )
                // @ts-expect-error
                window.ym(dataRest.yandexMetrika, 'init', {
                  clickmap: true,
                  trackLinks: true,
                  accurateTrackBounce: true,
                  webvisor: true
                })
              }
              setIsAdult(dataRest.isAdult)
              if (localStorage.getItem('preferredLanguage') == null) {
                setLanguage(dataRest.country)
              }
              const [dataSales, dataMenu] = await Promise.all([
                saleAPI.getAllSales(dataRest._id),
                menuAPI.getMenu(dataRest._id)
              ])
              setSales(dataSales)
              setMenu(dataMenu)
              setIsLoaded(true) // вот тут начинает грузиться Main
              setIsLoadingSpiner(false)
            } catch (error) {
              setIsLoadingSpiner(false)
              showAlert(error as string, 'danger')
            }
          }
          void fetchData()
        }
      })
      .catch((err) => showAlert(err.message, 'danger'))
  }, [])

  // установка цвета фона
  let styleBackgroundColor
  if (isLoaded) {
    styleBackgroundColor = {
      background:
        isImageLoaded && rest?.colorsSchema_id?.useBackgroundImg
          ? 'rgba(255, 255, 255, .1)'
          : rest.colorsSchema_id.backgroundColor
    }
  }

  // скрытие скролла при открытии модального окна
  React.useEffect(() => {
    document.body.style.overflow =
      isOpenDishModal || isOpenSaleModal ? 'hidden' : 'unset'
  }, [isOpenDishModal, isOpenSaleModal])

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const showAlert = (
    message: string,
    type: 'success' | 'danger' | 'warning'
  ) => {
    dispatch(setNotification({ message, type }))
  }

  const currentLanguage = useCurrentLanguage()
  const [selectedLanguage, setSelectedLanguage] =
    React.useState(currentLanguage)

  const handleLanguageChange = (event: any): void => {
    const newLanguage = event.target.value
    setSelectedLanguage(newLanguage)
    setLanguage(newLanguage as ECountry)
  }

  return (
    <CurrentLanguageProvider value={language}>
      <SetLanguageProvider value={changeLanguage}>
        <TextTranslationProvider value={t}>
          <YandexCityProvider>
            <div className='App' style={styleBackgroundColor}>
              {/* отображение фоновой картинки */}
              {rest?.colorsSchema_id?.useBackgroundImg && isImageLoaded && (
                <div className='App__main-image'>
                  <img
                    src={
                      `${BASE_URL_CDN}` +
                      '/' +
                      `${rest.colorsSchema_id.useBackgroundImg}`
                    }
                    onError={() => {
                      setIsImageLoaded(false)
                    }}
                  />
                </div>
              )}
              {/* Определение, сетевой ли ресторан */}
              {isNet ? (
                <div>
                  <div
                    style={{
                      width: '100px',
                      position: 'absolute',
                      top: '20px',
                      right: '20px'
                    }}
                  >
                    {/* селект выбора языка */}
                    <select
                      id='languageSelect'
                      value={selectedLanguage}
                      onChange={handleLanguageChange}
                    >
                      {net.data.rests[0].languages.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <RestsList rest={rest} net={net} setSelected={setSelected} />
                </div>
              ) : (
                // если не сетевой ресторан
                <div>
                  {message && <Notification message={message} type={type} />}
                  {/* загрузка Main */}
                  {isLoaded ? (
                    <>
                      <div className='scroll-to-top'>
                        {/* Кнопка прокрутки вверх */}
                        {isArrowUpButtonVisible && isScrollButtonVisible && (
                          <button
                            className={style.buttonUp}
                            style={styleCustomColor}
                            onClick={scrollToTop}
                          >
                            <img src={upArrow} alt='Кнопка прокрутки вверх' />
                          </button>
                        )}
                      </div>

                      <Switch>
                        <Route path={'/'} exact={true}>
                          <Helmet>
                            ‍<title>{rest.titleRest}</title>‍
                            <meta
                              name='description'
                              content={`Электронное меню заведения ${rest.titleRest} сделанное с помощью сервиса Easy QR`}
                            />
                            <meta
                              name='twitter:card'
                              content={`Электронное меню заведения ${rest.titleRest} сделанное с помощью сервиса Easy QR`}
                            />
                            <meta
                              name='twitter:title'
                              content={`Электронное меню заведения ${rest.titleRest} сделанное с помощью сервиса Easy QR`}
                            />
                            <meta
                              name='twitter:description'
                              content={`Электронное меню заведения ${rest.titleRest} сделанное с помощью сервиса Easy QR`}
                            />
                            <meta
                              property='og:title'
                              content={`Электронное меню заведения ${rest.titleRest} сделанное с помощью сервиса Easy QR`}
                            />
                            <meta
                              property='og:description'
                              content={`Электронное меню заведения ${rest.titleRest} сделанное с помощью сервиса Easy QR`}
                            />
                            <meta
                              property='og:site_name'
                              content={`Электронное меню заведения ${rest.titleRest} сделанное с помощью сервиса Easy QR`}
                            />
                          </Helmet>
                          <Main
                            rest={rest}
                            sales={sales}
                            openSaleModal={openSaleModal}
                            menu={menu}
                            openDishModal={openDishModal}
                            isAdult={isAdult}
                            setIsAdult={setIsAdult}
                            language={language}
                            setIsDishLoaded={setIsDishLoaded}
                            isDishLoaded={isDishLoaded}
                            setIsScrollButtonVisible={setIsScrollButtonVisible}
                          />
                        </Route>
                        <Route path={'/rests-list'} exact={true}>
                          <RestsList
                            rest={rest}
                            net={net}
                            setSelected={setSelected}
                          />
                        </Route>
                        {showCart ? (
                          <Route path={'/cart'}>
                            <Cart
                              rest={rest}
                              setIsScrollButtonVisible={
                                setIsScrollButtonVisible
                              }
                            />
                          </Route>
                        ) : null}
                        <Route path={'/person-sign-in'} exact={true}>
                          <AuthorizationUser
                            rest={rest}
                            setIsScrollButtonVisible={setIsScrollButtonVisible}
                          />
                        </Route>
                        <ProtectedRoute path={'/orders'} exact={true}>
                          <Orders
                            rest={rest}
                            setIsScrollButtonVisible={setIsScrollButtonVisible}
                          />
                        </ProtectedRoute>
                        <Route path={'/order-made'} exact={true}>
                          <OrderMade rest={rest} />
                        </Route>
                        <Route path={'/payment-success'}>
                          <PaymentSuccess
                            rest={rest}
                            setIsScrollButtonVisible={setIsScrollButtonVisible}
                          />
                        </Route>
                        <Route path={'/payment-failure'}>
                          <PaymentFailure
                            rest={rest}
                            setIsScrollButtonVisible={setIsScrollButtonVisible}
                          />
                        </Route>
                        <Route path={'/person-update'} exact={true}>
                          <PersonUpdate
                            rest={rest}
                            setIsScrollButtonVisible={setIsScrollButtonVisible}
                          />
                        </Route>
                        <Route path={'/person'} exact={true}>
                          <Person
                            rest={rest}
                            setIsScrollButtonVisible={setIsScrollButtonVisible}
                          />
                        </Route>
                        <Route path={'/pd'}>
                          <PD
                            rest={rest}
                            setIsScrollButtonVisible={setIsScrollButtonVisible}
                          />
                        </Route>
                        <Route path={'/adultNotConfirm'}>
                          <AdultIsNotConfirmed />
                        </Route>
                        <Route path={'/about-us'} exact={true}>
                          <AboutUs
                            rest={rest}
                            setIsScrollButtonVisible={setIsScrollButtonVisible}
                          />
                        </Route>
                        <Route path={'/terms-of-payment'} exact={true}>
                          <TermsOfPayment
                            rest={rest}
                            setIsScrollButtonVisible={setIsScrollButtonVisible}
                          />
                        </Route>
                        <Route
                          path={'/condition-for-returning-goods'}
                          exact={true}
                        >
                          <ConditonForReturning
                            rest={rest}
                            setIsScrollButtonVisible={setIsScrollButtonVisible}
                          />
                        </Route>
                        <Route path={'/oferta'} exact={true}>
                          <Offer
                            rest={rest}
                            setIsScrollButtonVisible={setIsScrollButtonVisible}
                          />
                        </Route>
                        /{' '}
                        <Route path='*'>
                          <NotFound rest={rest} />
                        </Route>
                      </Switch>
                    </>
                  ) : (
                    <CustomSpinner rest={rest} />
                  )}
                  {isOpenDishModal && (
                    <DishModal
                      rest={rest}
                      isNoImage={isNoImageDishInModal}
                      activeModifiers={activeModifiersInModal}
                      dish={dishInModal}
                      closeModal={closeModal}
                    />
                  )}
                  {isOpenSaleModal && (
                    <SaleModal
                      sale={saleInModal}
                      data={rest}
                      closeModal={closeModal}
                    />
                  )}
                </div>
              )}
            </div>
          </YandexCityProvider>
        </TextTranslationProvider>
      </SetLanguageProvider>
    </CurrentLanguageProvider>
  )
}

export default App
