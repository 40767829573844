import { FC } from 'react'
import style from './categories-card-view.module.css'
import CategoryCard from '../category-card/category-card'
import { TRest } from '../../utils/typesFromBackend'

interface ICategory {
  title: string
  _id: string
  url?: string
}

interface ICategoriesCardViewProps {
  rest: TRest
  arrayCategoriesInNavBar: ICategory[]
}

const CategoriesCardView: FC<ICategoriesCardViewProps> = ({
  rest,
  arrayCategoriesInNavBar
}) => {
  const { mainTextColor, backgroundColor } = rest.colorsSchema_id

  const cardTitleStyle = {
    color: mainTextColor,
    backgroundColor
  }

  return (
    <section
      id='elements-container'
      className={
        rest.colorsSchema_id.viewGrid
          ? style.containerTwoColumns
          : style.container
      }
    >
      {arrayCategoriesInNavBar.map((category) => (
        <CategoryCard
          key={category._id}
          cardID={category._id}
          cardImage={category.url}
          cardTitle={category.title}
          cardTitleStyle={cardTitleStyle}
          rest={rest}
        />
      ))}
    </section>
  )
}

export default CategoriesCardView
