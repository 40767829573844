/* eslint-disable multiline-ternary */
import { FC, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from '../../services/hooks/redux-hooks'
import { TOrder, TRest } from '../../utils/typesFromBackend'
import CartDeliveryBlock from '../../components/cart-delivery-block/cart-delivery-block'
import CartDetali from '../../components/cart-detali/cart-detali'
import { CartProductMemo } from '../../components/cart-product/cart-product'
import Header from '../../components/header/header'
import ModalWithCode from '../../components/modal-with-code/modal-with-code'
import style from './cart.module.css'
import * as orderAPI from '../../utils/api/order-api'
import {
  ADDED_CURRENT_ORDER,
  CLEAR_CART,
  USER_REQUEST_SUCCESS,
  resetOrderForm
} from '../../services/actions'
import { setNotification } from '../../services/actions/notification'
import { useTextTranslation } from '../../contexts/TextTranslation'
import { useCurrentLanguage } from '../../contexts/CurrentLanguageContext'
import { useLocalStorageState } from '../../services/hooks/useLocalStorageState'
import useLinkWithParams from '../../services/hooks/useLinkWithParams'

interface ICart {
  rest: TRest
  setIsScrollButtonVisible: (arg: boolean) => void
}

interface IActiveMethod {
  [key: string]: boolean
}
interface IObjectForCreateOrder {
  [key: string]: any
}

const Cart: FC<ICart> = ({ rest, setIsScrollButtonVisible }) => {
  const currentLanguage = useCurrentLanguage()
  const [isYookassaPayment, setIsYookassaPayment] = useState(false) // работает ли виджет Юкассы
  const [wantOrder, setWantOrder] = useState(true)
  const [isRemoteOrder, setIsRemoteOrder] = useState(true)
  const [activeMethods, setactiveMethods] = useLocalStorageState<IActiveMethod>(
    'activeMethods',
    {}
  )
  const [isCodeInput, setIsCodeInput] = useState(false)
  const [codeInput, setCodeInput] = useState('')
  const [phone, setPhone] = useState('')
  const [isDisableButton, setIsDisableButton] = useLocalStorageState(
    'isDisableButton',
    false
  )
  const [ucallerId, setUcallerId] = useState<number>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [offerData, setOfferData] = useLocalStorageState<any>('offerData', '')
  const [yandexDelivery, setYandexDelivery] = useLocalStorageState(
    'yandexDelivery',
    false
  )
  const [objectForCreateOrder, setObjectForCreateOrder] =
    useState<IObjectForCreateOrder>({})
  const dishesInCart = useSelector((store) => store.cart.dishes)
  const person = useSelector((store) => store.user)
  const dispatch = useDispatch()
  const translation = useTextTranslation()
  const createLink = useLinkWithParams()

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const showAlert = (
    message: string,
    type: 'success' | 'danger' | 'warning'
  ) => {
    dispatch(setNotification({ message, type }))
  }
  const search = useLocation().search
  // получаем номер столика из url
  const numberTable = new URLSearchParams(search).get('numberTable')
  const restID = new URLSearchParams(search).get('rest_id')
  const history = useHistory()

  const styleMainTextColor = {
    color: rest.colorsSchema_id.mainTextColor
  }
  const styleLoght = {
    background: rest.colorsSchema_id.loght
  }

  function toggleActiveMethod(name: string): void {
    const result: IActiveMethod = {}
    for (const key of Object.keys(activeMethods)) {
      result[key] = false
    }
    result[name] = true
    setactiveMethods(result)
  }

  function closeModal(): void {
    setIsCodeInput(false)
  }

  function createOrder(code: string, order?: IObjectForCreateOrder): void {
    if (code.length > 0) {
      objectForCreateOrder.userCode = code
      // создание заказа
      orderAPI
        .createOrder(objectForCreateOrder)
        .then((res: TOrder) => {
          setIsDisableButton(false)
          setIsLoading(false)
          if (res.paymentLink != null) {
            const redirectUrl = res.paymentLink
            window.location.href = redirectUrl
            return
          }
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (res.confirmationToken) {
            const domainWithSubdomain = window.location.origin
            const successUrlRedirect = domainWithSubdomain + '/payment-success'
            let returnUrl = createLink(successUrlRedirect)
            if (rest.isItRestaurantChain) {
              if (!returnUrl.includes('rest_id=')) {
                if (returnUrl.includes('?')) {
                  returnUrl += `&rest_id=${rest._id}`
                } else {
                  returnUrl += `?rest_id=${rest._id}`
                }
              }
            }
            // @ts-expect-error.
            const checkout = new window.YooMoneyCheckoutWidget({
              confirmation_token: res.confirmationToken, // Токен, который перед проведением оплаты нужно получить от ЮKassa
              return_url: returnUrl, // Ссылка на страницу завершения оплаты, это может быть любая ваша страница
              // При необходимости можно изменить цвета виджета, подробные настройки см. в документации
              // customization: {
              // Настройка цветовой схемы, минимум один параметр, значения цветов в HEX
              // colors: {
              // Цвет акцентных элементов: кнопка Заплатить, выбранные переключатели, опции и текстовые поля
              // control_primary: '#00BF96', //Значение цвета в HEX
              // Цвет платежной формы и ее элементов
              // background: '#F2F3F5' //Значение цвета в HEX
              // }
              // },
              error_callback: function (error: any) {
                console.log(error)
                setIsYookassaPayment(false)
                showAlert(translation('order-creation-error'), 'danger')
              }
            })
            // Отображение платежной формы в контейнере
            setIsYookassaPayment(true)
            checkout.render('payment-form')
            return
          }
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (res.incomingOrderId) {
            // заказ создан
            dispatch({ type: CLEAR_CART })
            if (!person.isLoggedIn) {
              // надо авторизировать пользователя если он не авторизован
              if (typeof res.user_id !== 'string') {
                dispatch({ type: USER_REQUEST_SUCCESS, user: res.user_id })
              }
              // заказ надо добавить в стейт
              dispatch({ type: ADDED_CURRENT_ORDER, order: res })

              if (numberTable != null) {
                history.push(
                  `/order-made?numberTable=${numberTable}&incoming_order_id=${
                    res.incomingOrderId
                  }${restID != null ? `&rest_id=${restID}` : ''}`
                )
              } else {
                history.push(
                  `/order-made?incoming_order_id=${res.incomingOrderId}${
                    restID != null ? `&rest_id=${restID}` : ''
                  }`
                )
              }
            }
          } else {
            setIsLoading(false)
            showAlert(translation('order-creation-error'), 'danger')
          }
        })
        .catch((err) => {
          setIsLoading(false)
          showAlert(err.message, 'danger')
        })
    } else {
      if (order != null) {
        orderAPI
          .createOrder(order)
          .then((res: TOrder) => {
            setIsDisableButton(false)
            setIsLoading(false)
            setOfferData('')
            localStorage.clear()
            dispatch(resetOrderForm())

            if (res.paymentLink != null) {
              const redirectUrl = res.paymentLink
              window.location.href = redirectUrl
              return
            }

            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (res.confirmationToken) {
              const domainWithSubdomain = window.location.origin
              const successUrlRedirect =
                domainWithSubdomain + '/payment-success'
              let returnUrl = createLink(successUrlRedirect)
              if (rest.isItRestaurantChain) {
                if (!returnUrl.includes('rest_id=')) {
                  if (returnUrl.includes('?')) {
                    returnUrl += `&rest_id=${rest._id}`
                  } else {
                    returnUrl += `?rest_id=${rest._id}`
                  }
                }
              }
              // @ts-expect-error.
              const checkout = new window.YooMoneyCheckoutWidget({
                confirmation_token: res.confirmationToken, // Токен, который перед проведением оплаты нужно получить от ЮKassa
                return_url: returnUrl, // Ссылка на страницу завершения оплаты, это может быть любая ваша страница
                // При необходимости можно изменить цвета виджета, подробные настройки см. в документации
                // customization: {
                // Настройка цветовой схемы, минимум один параметр, значения цветов в HEX
                // colors: {
                // Цвет акцентных элементов: кнопка Заплатить, выбранные переключатели, опции и текстовые поля
                // control_primary: '#00BF96', //Значение цвета в HEX
                // Цвет платежной формы и ее элементов
                // background: '#F2F3F5' //Значение цвета в HEX
                // }
                // },
                error_callback: function (error: any) {
                  console.log(error)
                  setIsYookassaPayment(false)
                  showAlert(translation('order-creation-error'), 'danger')
                }
              })
              // Отображение платежной формы в контейнере
              setIsYookassaPayment(true)
              checkout.render('payment-form')
              return
            }
            dispatch({ type: CLEAR_CART })

            if (res.incomingOrderId !== 0) {
              dispatch({ type: CLEAR_CART })

              // авторизовывать пользователя не надо
              // заказ надо добавить в стейт
              dispatch({ type: ADDED_CURRENT_ORDER, order: res })

              if (numberTable != null) {
                history.push(
                  `/order-made?numberTable=${numberTable}&incoming_order_id=${
                    res.incomingOrderId
                  }${restID != null ? `&rest_id=${restID}` : ''}`
                )
              } else {
                history.push(
                  `/order-made?incoming_order_id=${res.incomingOrderId}${
                    restID != null ? `&rest_id=${restID}` : ''
                  }`
                )
              }
            } else {
              showAlert(translation('order-creation-error'), 'danger')
            }
          })
          .catch((err) => {
            showAlert(err.message, 'danger')
          })
      }
    }
  }

  useEffect(() => {
    if (rest.tariff === 'Extend') {
      setWantOrder(true)
    } else {
      setWantOrder(false)
    }
  }, [rest.tariff])

  // выбирает активный метод заказа из сохраннного в localStorage
  useEffect(() => {
    if (Object.keys(activeMethods).length === 0) {
      const result: IActiveMethod = {}
      let flagActive = true
      rest.orderType_ids.forEach((orderType) => {
        if (flagActive && orderType.active) {
          result[orderType._id] = true
          flagActive = false
        } else {
          result[orderType._id] = false
        }
      })
      setactiveMethods(result)
    }
  }, [activeMethods, rest.orderType_ids])

  // определяем, дистаниционный заказ или нет
  useEffect(() => {
    if (numberTable != null) {
      setIsRemoteOrder(false)
    } else {
      setIsRemoteOrder(true)
    }
  }, [search])

  return (
    <section className={style.section} style={styleMainTextColor}>
      <div id='payment-form'></div>
      {isYookassaPayment ? (
        ''
      ) : (
        <>
          <Header
            title={wantOrder ? translation('want-order') : translation('cart')}
            rest={rest}
            showBottomCart={true}
            setIsScrollButtonVisible={setIsScrollButtonVisible}
          />
          {!wantOrder && isRemoteOrder && (
            <div className={style['cart-delivery-block']} style={styleLoght}>
              {rest.orderType_ids?.map((orderType) =>
                orderType.active ? (
                  <CartDeliveryBlock
                    title={orderType.title[currentLanguage]}
                    active={activeMethods[orderType._id]}
                    rest={rest}
                    nameActiveMethod={orderType._id}
                    toggleActiveMethod={toggleActiveMethod}
                    key={orderType._id}
                    orderType={orderType}
                    setYandexDelivery={setYandexDelivery}
                    yandexDelivery={yandexDelivery}
                  />
                ) : (
                  ''
                )
              )}
            </div>
          )}

          <div className={style['cart-products']}>
            {dishesInCart.length > 0 ? (
              dishesInCart.map((dish) => (
                <CartProductMemo
                  dish={dish.dish}
                  rest={rest}
                  prodCount={dish.quantity}
                  id={dish.id}
                  key={dish.id}
                />
              ))
            ) : (
              <div className={`${style.alert} ${style['alert-danger']}`}>
                {translation('empty')}
              </div>
            )}
          </div>
          <CartDetali
            setIsCodeInput={setIsCodeInput}
            wantOrder={wantOrder}
            isRemoteOrder={isRemoteOrder}
            rest={rest}
            activeMethodsOrderType={activeMethods}
            setObjectForCreateOrder={setObjectForCreateOrder}
            createOrder={createOrder}
            setPhone={setPhone}
            setUcallerId={setUcallerId}
            isDisableButton={isDisableButton}
            setIsDisableButton={setIsDisableButton}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setOfferData={setOfferData}
            offerData={offerData}
            isyandexDelivery={yandexDelivery}
          />
          {isCodeInput && (
            <ModalWithCode
              setInputCode={setCodeInput}
              codeInput={codeInput}
              closeModal={closeModal}
              rest={rest}
              createOrder={createOrder}
              phone={phone}
              ucallerId={ucallerId}
            />
          )}
        </>
      )}
    </section>
  )
}

export default Cart
