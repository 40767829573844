// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".categories-card-view_container__SxbBD,\n.categories-card-view_containerTwoColumns__2rzaf {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-around;\n  gap: 40px;\n}\n\n@media screen and (max-width: 768px) {\n  .categories-card-view_container__SxbBD,\n  .categories-card-view_containerTwoColumns__2rzaf {\n    display: grid;\n    grid-template-columns: 1fr;\n    margin: 0 15px;\n    place-items: center;\n  }\n\n  .categories-card-view_containerTwoColumns__2rzaf {\n    grid-template-columns: 1fr 1fr;\n    gap: 20px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/categories-card-view/categories-card-view.module.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;EACb,eAAe;EACf,6BAA6B;EAC7B,SAAS;AACX;;AAEA;EACE;;IAEE,aAAa;IACb,0BAA0B;IAC1B,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,8BAA8B;IAC9B,SAAS;EACX;AACF","sourcesContent":[".container,\n.containerTwoColumns {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-around;\n  gap: 40px;\n}\n\n@media screen and (max-width: 768px) {\n  .container,\n  .containerTwoColumns {\n    display: grid;\n    grid-template-columns: 1fr;\n    margin: 0 15px;\n    place-items: center;\n  }\n\n  .containerTwoColumns {\n    grid-template-columns: 1fr 1fr;\n    gap: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "categories-card-view_container__SxbBD",
	"containerTwoColumns": "categories-card-view_containerTwoColumns__2rzaf"
};
export default ___CSS_LOADER_EXPORT___;
