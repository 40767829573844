import React, { FC, useEffect, useState } from 'react'
import { TSale } from '../../utils/typesFromBackend'
import Sale from '../sale/sale'
import style from './sales.module.css'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useInView } from 'react-intersection-observer'

interface ISales {
  sales: TSale[]
  openSaleModal: (sale: TSale) => void
}

const Sales: FC<ISales> = ({ sales, openSaleModal }) => {
  const [activeSlide, setActiveSlide] = React.useState(false)

  useEffect(() => {
    const dateNow = new Date()
    dateNow.setHours(0, 0, 0, 0)

    const filteredSales = sales.filter((sale) => {
      if (!sale.action) {
        return false
      }

      if (sale.dateStartSales.length > 0 && sale.dateFinishSales.length > 0) {
        const startSaleDate = new Date(sale.dateStartSales)
        const finishSaleDate = new Date(sale.dateFinishSales)

        return dateNow >= startSaleDate && dateNow <= finishSaleDate
      }

      return false
    })

    setFilterSales(filteredSales)
  }, [sales])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
    swipe: true,
    arrows: false,
    beforeChange: () => {
      setActiveSlide(true)
    },
    afterChange: () => setActiveSlide(false)
  }
  const [isSales, setIsSales] = useState(false)
  const [filterSales, setFilterSales] = useState<TSale[]>([])

  const [ref, inView] = useInView({
    threshold: 0.22
  })

  const groupSquare = document.getElementById('groupSquare') as HTMLElement
  const sliderDiv = document.getElementById('sliderDiv') as HTMLElement
  const subcategoriesContainer = document.getElementById(
    'subcategories-container'
  )

  // отсюда регулируется видимость Navbar в зависимости от того, виден ли блок с рекламными баннерами
  React.useEffect(() => {
    if (
      subcategoriesContainer != null &&
      groupSquare != null &&
      sliderDiv != null
    ) {
      if (!inView) {
        // если рекламные баннеры вышли из области видимости - то для горизонтального меню делаем fixed
        sliderDiv.style.position = 'fixed'
        sliderDiv.style.right = '0px'
        sliderDiv.style.left = '0px'
        subcategoriesContainer.style.marginTop = '60px'
        groupSquare.style.position = 'fixed'
        groupSquare.style.right = '0px'
        groupSquare.style.left = '-1px'
      } else {
        sliderDiv.style.position = 'initial'
        groupSquare.style.position = 'absolute'
        subcategoriesContainer.style.marginTop = '0'
      }
    }
  }, [inView])

  React.useEffect(() => {
    const arraySales: TSale[] = []
    const arraySalesActive: TSale[] = []
    const dateNow = new Date()
    dateNow.setHours(0, 0, 0, 0)
    if (sales.length > 0) {
      sales.forEach((sale) => {
        if (sale.action && !sale.showInAutoPopup) {
          arraySalesActive.push(sale)
        }
      })
    }
    if (arraySalesActive.length > 0) {
      arraySalesActive.forEach((sale) => {
        let flag = true
        if (sale.dateStartSales.length > 0) {
          const startSaleArray = sale.dateStartSales.split('-')
          if (startSaleArray.length === 3) {
            const startSaleDate = new Date(
              parseInt(startSaleArray[0]),
              parseInt(startSaleArray[1]) - 1,
              parseInt(startSaleArray[2])
            )
            if (dateNow < startSaleDate) {
              flag = false
            }
          }
        }
        if (sale.dateFinishSales.length > 0) {
          const finishSaleArray = sale.dateFinishSales.split('-')
          if (finishSaleArray.length === 3) {
            const finishSaleDate = new Date(
              parseInt(finishSaleArray[0]),
              parseInt(finishSaleArray[1]) - 1,
              parseInt(finishSaleArray[2])
            )
            if (dateNow > finishSaleDate) {
              flag = false
            }
          }
        }
        if (flag) {
          arraySales.push(sale)
        }
      })
    }
    setFilterSales(arraySales)
    if (arraySales.length > 0) {
      setIsSales(true)
    } else {
      setIsSales(false)
    }
  }, [sales])

  return (
    <div className={style.eventsBlock} ref={ref}>
      {isSales && (
        <Slider {...settings}>
          {filterSales.map((item: TSale) => (
            <Sale
              key={item._id}
              data={item}
              openSaleModal={activeSlide ? () => { } : openSaleModal}
            />
          ))}
        </Slider>
      )}
    </div>
  )
}

export default Sales
