import React from 'react'

// Функция для закрепления Navbar под Sales
export const fixNavbarUnderSales = (
  sliderDivRef: React.RefObject<HTMLDivElement>,
  groupSquareRef: React.RefObject<HTMLDivElement>
): void => {
  if (sliderDivRef.current !== null && groupSquareRef.current !== null) {
    sliderDivRef.current.style.position = 'fixed'
    sliderDivRef.current.style.right = '0px'
    sliderDivRef.current.style.left = '0px'
    groupSquareRef.current.style.position = 'fixed'
    groupSquareRef.current.style.right = '0px'
    groupSquareRef.current.style.left = '-1px'
  }
}
